<template>
    <div>        
        <div class="container">
            <div  width="100%">
                <!-- <div class="section">
                    <span slot="label" class="title"><i class="el-icon-date"></i> 产品包管理</span>
                </div> -->
                <div style="display:flex;flex-direction: row-reverse;">                   
                    
                    <el-button type="text" @click="onTemplateClick" style="margin-right:10px;">导入模板下载</el-button>                 
                    <el-button type="primary"  @click="onOpenUpload" style="margin-right:10px;">产品包导入</el-button>
                    <el-button @click="newGroup"  icon="el-icon-plus" type="primary">新建</el-button>
                </div>

                <el-form  ref="form" :model="searchForm" label-width="80px"  label-position="left">
                 
                        <!-- <el-form-item label="省份/车站">
                                        <el-cascader 
                                                :show-all-levels="false"                                
                                                placeholder="请选择省份/车站"
                                                :options="areaStationList"
                                                :props="props"
                                                v-model="searchForm.stationId"  
                                                clearable                       
                                        ></el-cascader>
                        </el-form-item> -->
                        <el-row>
                        <!-- <el-form-item label="分类" style="width:372px;">                            
                            <el-select @change="packageChanged" clearable multiple v-model="searchForm.packageIds"  placeholder="请选择产品包分类">
                                            <el-option
                                            v-for="item in packageList"
                                            :key="item.value"
                                            :label="item.label"
                                            :value="item.value">
                                            </el-option>
                            </el-select>
                        </el-form-item> -->

                        <el-form-item label="售卖状态"  style="margin-left:00px;">
                            <el-select v-model="searchForm.saleStatus"   placeholder="请选择售卖状态">
                                <el-option
                                    v-for="item in statusList"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        
                        <el-form-item label="上刊状态"  >
                            <el-select v-model="searchForm.playStatus"   placeholder="请选择上刊状态">
                                <el-option
                                    v-for="item in statusList"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        </el-row>
                        <el-row>
                        <el-form-item label="关键字"  >
                                <el-input  v-model="searchForm.key" placeholder="请输入产品包关键字"></el-input>
                        </el-form-item>
                        <el-button style="margin-left:30px;height: 20px;" type="primary" @click="onGroupQuery">检索</el-button>
                        </el-row>
                    
                </el-form>
                
                <el-tag @click="onfilter(1)" :class="[flag==1?'tagSel':'tag']">全网联播</el-tag>
                <el-tag @click="onfilter(2)" :class="[flag==2?'tagSel':'tag']">全省联播</el-tag>
                <el-tag @click="onfilter(7)" :class="[flag==7?'tagSel':'tag']">特殊节点联播</el-tag>
                <el-tag @click="onfilter(0)" :class="[flag==0?'tagSel':'tag']">全部</el-tag>
                
                <div class="card-group" style="display:flex;justify-content:space-around;"> 
                    <div class="group-item" v-for="(item,groupIndex) in productList" :key="item">
                                    <el-card class="box-card" >
                                        <div class="clearfix">
                                            <el-tooltip class="item" effect="dark" :content="item.name" placement="top-end">
                                                <el-button  class="product-name" type="text"  @click="onEdit(item)">
                                                    {{item.name.length>12?item.name.substring(0,12)+'...':item.name}}
                                                </el-button>
                                            </el-tooltip>
                                            <el-tooltip class="item" effect="dark" placement="right" content="删除">
                                             <el-button  type="text" class="el-icon-delete" style="float: right; padding: 0px 0;margin-top: -8px;font-size: 25px;"  circle @click="onDelete(item)"></el-button>
                                             </el-tooltip>
                                            <el-tooltip class="item" effect="dark" placement="bottom" content="编辑">
                                                <el-button  type="text" class="el-icon-edit" style="float: right; padding: 0px 0;margin-top: -8px;font-size: 25px;"  circle @click="onEdit(item)"></el-button>
                                            </el-tooltip>
                                            <el-tooltip class="item" effect="dark" placement="top" content="Excel">
                                                <el-button  type="text" class="el-icon-document" style="float: right; padding: 0px 0;margin-top: -8px;font-size: 25px;"  circle @click="onExport(item,1)"></el-button>
                                            </el-tooltip>
                                            <!-- <el-tooltip class="item" effect="dark" placement="left" content="PPT">
                                             <el-button  type="text" class="el-icon-set-up" style="float: right; padding: 0px 0;margin-top: -8px;font-size: 25px;"  circle @click="onExport(item,2)"></el-button>
                                             </el-tooltip>-->
                                        </div> 
                                        <div  class="text item">
                                            <!-- <div style="display:flex;justify-content:center">
                                                <el-image :src="item.img" style="width: 350px; height: 250px" :fit="'fill'">
                                                    <div class="image-slot">
                                                        加载中<span class="dot">...</span>
                                                    </div>
                                                </el-image>
                                            </div> -->
                                            <div style="margin-top:20px;">
                                                <el-row>
                                                    <el-col :span="6" class="subtitle">资源总量</el-col>
                                                    <el-col :span="18">{{item.totalCount}}个</el-col>
                                                </el-row>
                                                <el-row v-if="item.count">
                                                    <el-col :span="6" class="subtitle">资源限量</el-col>
                                                    <el-col :span="18">{{item.count}}个</el-col>
                                                </el-row>
                                                <!-- <el-row v-if="item.length">
                                                    <el-col :span="6" class="subtitle">版本时长</el-col>
                                                    <el-col :span="18">{{item.length}}秒</el-col>
                                                </el-row>
                                                <el-row v-if="item.times">
                                                    <el-col :span="6" class="subtitle">投放频次</el-col>
                                                    <el-col :span="18">{{item.times}}次/天</el-col>
                                                </el-row> -->
                                                <el-row>
                                                    <el-col :span="6" class="subtitle">价　　格</el-col>
                                                    <el-col :span="18" style="color:red">￥{{item.cost}}万元</el-col>
                                                </el-row>
                                                <el-row>
                                                    <el-col :span="6" class="subtitle">折　　扣</el-col>
                                                    <el-col :span="18" style="color:red">{{item.discount}}</el-col>
                                                </el-row>
                                                <el-row>
                                                    <el-col :span="6" class="subtitle">售卖时期</el-col>
                                                    <el-col :span="18">{{item.saleDate}}</el-col>
                                                </el-row>
                                                <!-- <el-row>
                                                    <el-col :span="6" class="subtitle">投放时期</el-col>
                                                    <el-col :span="18">{{item.playDate}}</el-col>
                                                </el-row> -->
                                                <el-row>
                                                    <el-col :span="6" class="subtitle">说　　明</el-col>
                                                    <el-col :span="18">
                                                        <div v-if="item.remark.length>20">
                                                            <div v-if="!item.collapse">
                                                                <span style="color:#3EA7FF;cursor:pointer;margin-left:300px;" @click="item.collapse=!item.collapse" type="primary">收起</span>
                                                               
                                                                <p v-html="item.remark.replace('\r\n','\n').split('\n').map(item => `<p>${item}</p>`).join('')"></p>
                                                                
                                                            </div>
                                                            <div v-else>
                                                                <span style="color:#3EA7FF;cursor:pointer;margin-left:300px;" @click="item.collapse=!item.collapse" type="primary">展开</span>
                                                              
                                                            </div>
                                                        </div>
                                                        <div v-else>
                                                            <p v-html="item.remark.replace('\r\n','\n').split('\n').map(item => `<p>${item}</p>`).join('')"></p>
                                                        </div>
                                                        
                                                    </el-col>
                                                </el-row>
                                            </div>
                                        </div>
                                    </el-card>
                    </div>
                </div>

                
                <div>
                    <el-dialog title="产品包设置" v-model="resultDialogVisible" width="60%" @open="open()"
                                    :modal-append-to-body='false'
                                    :close-on-click-modal='false'
                                    append-to-body>

                        <div style="margin-top:-55px;">
                            
                            <el-form  ref="form" :model="groupForm" label-width="120px" style="margin-top:20px;" label-position="left">
                                <el-row>
                                    <el-form-item label="产品包名称" >
                                        <el-input  v-model="groupForm.groupName" style="width:295px;" placeholder="请输入产品包名称"></el-input>
                                    </el-form-item>
                                </el-row>
                                <el-row>
                                    <el-form-item  label="刊例费用" style="margin-left:0px;">   
                                        <el-input  v-model="groupForm.cost10" style="width:205px;" placeholder="请输入刊例"></el-input>
                                        <span class="beizhu">万元/月</span>
                                    </el-form-item> 

                                </el-row>
                                <el-row>
                                    <el-form-item label="售卖时期" >    
                                        <el-date-picker                                
                                            v-model="groupForm.saleDateArray"
                                            style="width:262px;"
                                            type="daterange"
                                            unlink-panels
                                            range-separator="至"
                                            start-placeholder="开始日期"
                                            end-placeholder="结束日期"                                        
                                            :clearable="false"
                                            :shortcuts="shortcuts"
                                            @change ="handleDateChange"
                                            >
                                        </el-date-picker>
                                    </el-form-item>  
                                    </el-row>
                                <!--el-row>
                                    <el-form-item label="投放时期"  style="margin-left:0px;">    
                                        <el-date-picker                                
                                            v-model="groupForm.playDateArray"
                                            style="width:262px;"
                                            type="daterange"
                                            unlink-panels
                                            range-separator="至"
                                            start-placeholder="开始日期"
                                            end-placeholder="结束日期"                                        
                                            :clearable="false"
                                            :shortcuts="shortcuts"
                                            @change ="handleDateChange"
                                            >
                                        </el-date-picker>
                                    </el-form-item>  
                                </el-row-->
                                <el-row>
                                    
                                </el-row>
                                <!-- <el-row>   
                                    <el-form-item label="版本时长" style="width:372px;">                            
                                        <el-select @change="lengthChanged" v-model="groupForm.length"  placeholder="请选择版本时长">
                                            <el-option
                                            v-for="item in lengthList"
                                            :key="item.value"
                                            :label="item.label"
                                            :value="item.value">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                    <el-form-item label="每日投放频次" style="margin-left:30px;width:372px;">                            
                                        <el-select @change="timesChanged" v-model="groupForm.times"  placeholder="请选择每日投放频次">
                                            <el-option
                                            v-for="item in timesList"
                                            :key="item.value"
                                            :label="item.label"
                                            :value="item.value">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-row> -->
                                <!-- <el-row>  -->
                                    
                                    <el-form-item label="限定数量" >
                                        <el-input  v-model="groupForm.count" style="width:205px;" placeholder="请输入限定数量"></el-input>
                                        <span class="beizhu">投放资源数量，不限定时不需填写</span>
                                    </el-form-item>      

                                    <!-- <el-form-item  label="15秒费用" style="margin-left:30px;">   
                                        <el-input  v-model="groupForm.cost15" style="width:205px;" placeholder="请输入刊例"></el-input>
                                        <span class="beizhu">万元</span>
                                    </el-form-item>  -->
                                <!-- </el-row> -->
                                <el-row>  
                                    <el-form-item label="分类" style="width:372px;">                            
                                        <el-select @change="packageChanged" v-model="groupForm.packageId"  placeholder="请选择产品包分类">
                                            <el-option
                                            v-for="item in packageList"
                                            :key="item.value"
                                            :label="item.label"
                                            :value="item.value">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>

                                    <!-- <el-form-item label="产品标签" >
                                        <el-input  v-model="groupForm.packageNames" style="width:615px;" placeholder="请输入产品包标签"></el-input>
                                        <span class="beizhu">多个标签以逗号分隔</span>
                                    </el-form-item> -->

                                    <!-- <el-form-item  label="套餐费用" style="margin-left:30px;">   
                                        <el-input  v-model="groupForm.cost" style="width:205px;" placeholder="请输入刊例"></el-input>
                                        <span class="beizhu">万元</span>
                                    </el-form-item>                                     -->
                                </el-row>
                                <!-- <el-row>                                    
                                    <el-form-item  label="LED最低折扣" >   
                                        <el-input-number style="width:222px;" 
                                            controls-position="right" 
                                            :precision="2" :min="1" :max="10" 
                                            v-model="groupForm.minLedDiscount" placeholder="请输入最低折扣"></el-input-number>                                        
                                    </el-form-item>
                                    <el-form-item label="灯箱最低折扣"  style="margin-left:50px;">
                                        <el-input-number style="width:222px;" 
                                            controls-position="right" 
                                            :precision="2" :min="1" :max="10" 
                                            v-model="groupForm.minDxDiscount" placeholder="请输入最低折扣"></el-input-number>
                                    </el-form-item>
                                </el-row> -->
                                <el-row>                                    
                                                                  
                                </el-row>

                                <el-row>
                                    <el-form-item label="产品包说明" style="width: 100%;">
                                        <el-input v-model="groupForm.remark" type="textarea" autocomplete="off" :rows="3"
                                                                style="width: 615px;"></el-input>
                                    </el-form-item>
                                </el-row>
                                <!-- <el-row>
                                    <div class="text-ele">
                                    <my-upload-file-form-item label="宣传海报" :limit="1"  :subDir="'product'" :file-list="groupForm.imgArr" @change="getImgArr" v-model="groupForm.imgArr"/>                                    
                                    </div>                                    
                                </el-row> -->
                                <el-row class="source">
                                    <el-divider>资源</el-divider>    
                                    <div style="display:flex;justify-content:space-between;width: 100%;margin-top:5px;">
                                        <el-input  v-model="searchForm.detailKey" style="width:295px;" placeholder="请输入检索关键字"></el-input>
                                        <el-button type="primary" style="margin-top:-50px;" @click="addDialogVisible=true">添加资源</el-button>
                                    </div>
                                    <!-- :data="MediaList.slice((currentPage-1)*pageSize,currentPage*pageSize)" -->
                                    <el-table                              
                                        
                                        :data="MediaList.filter(data => !searchForm.detailKey || data.station.toLowerCase().includes(searchForm.detailKey.toLowerCase())
                                             || data.name.toLowerCase().includes(searchForm.detailKey.toLowerCase()))"
                                        border
                                        :header-cell-style="{background:'#eef1f6',color:'#606266'}"
                                        style="width: 100%;margin-top: 10px;font-size: x-small;">	                                                                                                                     
                                        <el-table-column prop="station" align="center" sortable label="车站"></el-table-column>
                                        <el-table-column prop="mediaGrade" align="center" width="100px" sortable label="媒体等级"></el-table-column> 
                                        <el-table-column prop="name" align="center" sortable label="套餐名称"></el-table-column> 
                                        <el-table-column prop="position" align="center" sortable label="位置"></el-table-column> 
                                        <el-table-column prop="medias" align="center" sortable label="媒体编码">
                                            <template v-slot="scope">
                                                <!-- {{ scope.row.medias}} -->
                                                
                                                    <span v-if="scope.row.medias.length<=30">
                                                        {{scope.row.medias}}
                                                    </span>
                                                    <span v-else>
                                                        <el-tooltip class="item" effect="dark" :content="scope.row.medias" placement="top">
                                                            <span>{{scope.row.medias.substring(0,30)}}...</span>
                                                        </el-tooltip>
                                                    </span>
                                                    <!-- <span v-else-if="index<5 || (index>=5 && scope.row.showMore)">
                                                        <div v-if="item.images.length>0" style="color: #409EFF;cursor: pointer;" @click="openMedia(item.images)">{{item.mediaCode}}</div>
                                                        <div v-else>{{item.mediaCode}}</div>                                    
                                                    </span>
                                                    
                                                <div v-if="scope.row.mediaInfo.length>5" style="color:#409EFF">
                                                    <span v-if="scope.row.showMore" @click="scope.row.showMore=false">收起</span><span v-else  @click="scope.row.showMore=true">更多</span>
                                                </div>     -->
                                                
                                            </template>
                                        </el-table-column> 
                                        <el-table-column prop="typeName" align="center" sortable label="媒体形式"></el-table-column>
                                        <!-- <el-table-column prop="position" align="center" sortable label="位置"></el-table-column> -->
                                        <el-table-column prop="totalCount" align="center" sortable label="数量"></el-table-column> 
                                        <el-table-column prop="mediaCode" align="center"  sortable label="状态">
                                            <template v-slot="scope">
                                                <span  :style="scope.row.statusName=='无效'?'color:red':''" >{{scope.row.statusName}}</span>
                                                
                                            </template>
                                        </el-table-column>
                                        <!-- <el-table-column prop="mediaCode" align="center" sortable label="媒体编码">
                                            <template v-slot="scope">
                                                <span v-if="scope.row.isNew==1" style="color:red" >{{scope.row.mediaCode}}</span>
                                                <span v-else >{{scope.row.mediaCode}}</span>
                                            </template>
                                        </el-table-column> -->
                                        <!-- <el-table-column prop="address" align="center" sortable label="具体位置"></el-table-column>
                                        <el-table-column prop="count" align="center" sortable label="数量"></el-table-column>
                                        <el-table-column prop="areaPercent" align="center" sortable label="面积占比"></el-table-column>
                                        <el-table-column prop="adSize" align="center" sortable label="广告尺寸"></el-table-column>       
                                        <el-table-column prop="adArea" align="center" sortable label="广告面积"></el-table-column>                               -->
                                        <el-table-column prop="id" align="center" label="操作" width="100">
                                            <template v-slot="scope">
                                                <i class="el-icon-delete" color="red" @click="onRemove(scope.$index)"></i>
                                            </template>
                                        </el-table-column>
                                    </el-table> 
                                    <!-- <el-pagination
                                        @size-change="handleSizeChange"
                                        @current-change="handleCurrentChange"
                                        :current-page="currentPage"
                                        :page-sizes="[20,50, 100]"
                                        :page-size="pageSize"
                                        layout="total, sizes, prev, pager, next, jumper"
                                        :total="MediaList.length">
                                    </el-pagination> -->
                                    总共{{MediaList.length}}条
                                    
                                    <el-dialog title="添加资源" v-model="addDialogVisible" width="70%" 
                                        :modal-append-to-body='false'
                                        :close-on-click-modal='false'
                                         @close='closeAddDialog'
                                        append-to-body>
                                        <select-media ref="newMediaObj" :data-type="2"/>
                                        <!-- <span class="dialog-footer">
                                            <el-button type="primary" @click="onAdd">加入</el-button>
                                        </span> -->
                                        <div style="display:flex;flex-direction:row-reverse;width: 100%;">
                                            <el-button type="primary" @click="onAdd">加入</el-button>
                                        </div>
                                    </el-dialog>
                                </el-row>
                            </el-form>
                        </div>   
                        <template #footer>
                            <span class="dialog-footer">
                                <el-button @click="resultDialogVisible = false">关闭</el-button>
                                <el-button type="primary" @click="saveEdit">保存</el-button>
                            </span>
                        </template>
                    </el-dialog>
                </div>

                <el-dialog title="导入产品包" v-model="importDialogVisible" width="40%"
                            :modal-append-to-body='false'
                            :close-on-click-modal='false'
                            @close="importDialogVisible = false" 
                            append-to-body>
                        <el-form :model="importForm" inline style="margin-top: 30px;">
                            <el-row>
                                <el-form-item label="选择产品包Excel文件">
                                        <el-upload
                                                class="upload-demo"
                                                ref="upload"
                                                action="string"
                                                :http-request="uploadFile"
                                                :limit="1"
                                                :auto-upload="true">
                                                <!-- <el-button slot="trigger" size="small" type="primary">选取文件</el-button> -->
                                                <el-button style="margin-left: 10px;" size="small" type="success" @click="submitUpload">选择文件</el-button>
                                        </el-upload>
                                        <!-- <my-upload-file-form-item label="媒体资源Excel文件" :limit="1"  :subDir="'upload'" :file-list="curItem.excelArr" v-model="curItem.imgArr"/>                                     -->
                                </el-form-item>
                            </el-row>                                         
                        </el-form>

                        <div slot="footer" style="display:flex;justify-content:center">
                            <el-button @click="importDialogVisible = false">关闭</el-button>
                            <el-button v-if="logId!==0" style="margin-left:20px;" type="primary" @click="onSaveImport">保存</el-button>
                        </div>
            
                    </el-dialog>

            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import SelectMedia from './SelectMedia.vue';
import {getToken} from "@/utils/auth";
import {SaveProductGroup,SetGroupStatus,ExportPackage,DelGroup} from "@/api/operate";
import MyUploadFileFormItem from "@/components/MyUploadFileFormItem";
import {getFileUrl} from "@/api/file";
import {DownloadTemplateFile,ImportDataToDB} from "@/api/updown";
// import ProductTable from '@/views/Business/Smart/ProductTable';

export default {
    name: 'baseform',
    components:{SelectMedia,MyUploadFileFormItem},//,ProductTable
    computed: {...mapGetters(['mediaTypeList','packageList','positionList','GetProductList','areaStationList','GetGroupDetail','ImportDataToTmp'])},
    data() {
        return {
          resultDialogVisible:false,
          addDialogVisible:false,
          searchForm:{
              stationId:"",
            //   saleStatus:[],
              saleStatus:'',
              playStatus:'',
              key:'',
              packageIds:[],
              detailKey:'',
          },
          // 当前页
          currentPage: 1,
        // 每页多少条
          pageSize: 20,
          groupForm:{
              groupId:'',
              groupName:'',
            
              saleDateArray:[],
              playDateArray:[],
              count:'',
              cost:'',         
              cost10:'', 
              cost15:'',      
              remark:'',
              length:'10',
              times:100,
            //   packageIds:'',
              packageNames:'',
              packageId:'',
              imgArr:[],
            //   AddList:'',
          },
            timesList:[{label:'100次',value:100},{label:'200次',value:200},{label:'300次',value:300},{label:'400次',value:400},
                {label:'500次',value:500},{label:'600次',value:600},{label:'700次',value:700}],
            lengthList:[{label:'10秒',value:'10'},{label:'15秒',value:'15'}],
            statusList:[{label:'全部',value:''},{label:'正常',value:'正常'},{label:'未开始',value:'未开始'},{label:'已过期',value:'已过期'}],

          MediaList:[],
          isLed:true,

          productList:[],
          flag:0,

            importDialogVisible:false,
            importForm:{
            
            },
            logId:0,
        };
    },
    
    async mounted() {      
      await this.$store.dispatch('operate/areaStationList');
      await this.$store.dispatch('operate/mediaTypeList');
      await this.$store.dispatch('operate/positionList');
      await this.$store.dispatch('operate/packageList').then(()=>{
        console.log('aa',this.packageList)
      });
      await this.$store.dispatch('operate/GetProductList',{}).then(()=>{
                this.productList = this.GetProductList;
      })      
    },



    methods: {
        newGroup(){
            // console.log('aaa')
            this.groupForm.groupId='';
            this.groupForm.groupName='';
            // this.groupForm.typeId=1;
            // this.groupForm.cost15='';
            // this.groupForm.cost10='';
            this.groupForm.cost='';
            this.groupForm.minLedDiscount=4;
            this.groupForm.minDxDiscount=3;
            this.groupForm.isFixedPrice=0;
            this.groupForm.count='';
            this.groupForm.count10='';
            this.groupForm.count15='';
            this.groupForm.remark='';
            // this.groupForm.packageIds='';
            this.groupForm.packageNames='';
            this.groupForm.packageId='',

            this.groupForm.imgArr=[];
            this.MediaList=[];
            // this.isLed=true;
            // this.groupForm.AddList=[],
            this.resultDialogVisible = true;
        },

        async onSaveImport(){
            await ImportDataToDB({userId:getToken(),fileType:99, logId:this.logId}).then(()=>{                
                this.$message.success('入库成功！');
                // return;
                this.$refs.upload.clearFiles();
                this.importDialogVisible = false;
                this.onGroupQuery();
            })
            
        },

        async uploadFile(param) {    
            console.log('param',param);
            const formData = new FormData()
            formData.append('fileInfo', param.file);
            const loading = this.$loading({
                lock: true,
                text: '导入中...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            await this.$store.dispatch("updown/ImportDataToTmp", {
                userId:getToken(),typeId:99,
                formData
            }).then(res=>{
                loading.close();
                this.$message.success('Excel文件读取成功');
                this.logId=this.ImportDataToTmp[0].logId;
                console.log('logId',this.logId);
                // this.onGroupQuery();
                // this.importDialogVisible=false;
            }).catch(error => {
                loading.close();
            })
            
        },

        onOpenUpload(){
            
            this.logId=0;
            
            this.importDialogVisible = true;
            // 
        },

        async onTemplateClick() {
            await DownloadTemplateFile({userId:getToken(),typeId:99});
        },

        async onDelete(item){
            this.$confirm('是否确认删除选中产品包？ 是否继续?', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(async () => {    
                        DelGroup({userId:getToken(),groupId:item.groupId});                    
						this.$message.success('删除成功');
                        for(let index in this.productList){
                            if(this.productList[index].groupId==item.groupId){
                                this.productList.splice(index,1);
                            }
                        }                        
					})
        },

        onfilter(flag){
            // console.log('guolv')
            this.flag=flag;
            if (flag==0){
                this.productList = this.GetProductList;
            }else{
                this.productList = this.GetProductList.filter(item=>item.packageIds==flag);
            }
        },

        async onExport(item,flag){
            // if (flag==2)
            const loading = this.$loading({
                    lock: true,
                    text: '疯狂制作中...',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
            await ExportPackage({userId: getToken(),groupId:item.groupId,flagId:flag}).then(()=>{
                    loading.close();
                })        
        },

        submitUpload() {
            this.$refs.upload.submit();
        },

        async onEdit(item){
            await this.$store.dispatch('operate/GetGroupDetail',{groupId:item.groupId}).then(()=>{
                this.groupForm.groupId=this.GetGroupDetail[0][0].groupId;
                this.groupForm.groupName=this.GetGroupDetail[0][0].name;
                this.groupForm.times=this.GetGroupDetail[0][0].times;
                this.groupForm.length=this.GetGroupDetail[0][0].length;
                // this.groupForm.packageIds = this.GetGroupDetail[0][0].packageIds;
                this.groupForm.packageNames = this.GetGroupDetail[0][0].packageNames;
                this.groupForm.packageId = this.GetGroupDetail[0][0].packageIds*1;
                var imgs=this.GetGroupDetail[0][0].img;
                this.groupForm.imgArr=[];
                console.log('img',imgs)
                if (imgs && imgs!=''){
                    var tmp = imgs.split(',');
                    tmp.forEach(element => {
                        var name=element;
                        var url="";//getFileUrl({name: element});
                        this.groupForm.imgArr.push({name,url});
                    });
                }

                // this.groupForm.imgArr.push({name: 'food.jpeg', url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'}, {name: 'food2.jpeg', url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'})

                // console.log('aa',this.GetGroupDetail[0][0],this.GetGroupDetail[0][0].saleSDate)
                
                if (this.GetGroupDetail[0][0].saleSDate){                    
                    this.groupForm.saleDateArray=[this.GetGroupDetail[0][0].saleSDate.replace(/\//g,'-').split(' ')[0],this.GetGroupDetail[0][0].saleEDate.replace(/\//g,'-').split(' ')[0]];
                }
                // console.log('aa')
                if (this.GetGroupDetail[0][0].playSDate){
                    // this.groupForm.playDateArray=[this.GetGroupDetail[0][0].playSDate.replace(/\//g,'-').split(' ')[0],this.GetGroupDetail[0][0].playEDate.replace(/\//g,'-').split(' ')[0]];
                }
                // console.log('bb')
                this.groupForm.cost=this.GetGroupDetail[0][0].cost;
                this.groupForm.cost10=this.GetGroupDetail[0][0].cost10;
                this.groupForm.cost15=this.GetGroupDetail[0][0].cost15;
                // this.groupForm.minLedDiscount=this.GetGroupDetail[0][0].minLedDiscount;
                // this.groupForm.minDxDiscount=this.GetGroupDetail[0][0].minDxDiscount;
                // this.groupForm.isFixedPrice=this.GetGroupDetail[0][0].isFixedPrice;
                this.groupForm.count=this.GetGroupDetail[0][0].count;
                this.groupForm.remark=this.GetGroupDetail[0][0].remark;
                // console.log('this.groupForm',this.groupForm,this.GetGroupDetail)
                this.MediaList=this.GetGroupDetail[1];
                // if (this.GetGroupDetail[0][0].typeId==1){
                //     this.isLed=true;
                // }                    
                // else{
                //     this.isLed=false;
                // }
                // console.log('aa',this.groupForm)
                this.resultDialogVisible = true;
            })
            
        },

        async onSetStatus(item){
            // await dealDeleteOperation({deletePromise: SetGroupStatus({userId:getToken(), groupId: item.groupId})})
            await SetGroupStatus({userId:getToken(), groupId: item.groupId}).then(()=>{
                this.onGroupQuery();
            })
			// 
        },

        onRemove(index){
            // console.log('aaa',index)
            this.MediaList.splice(index,1)
        },

        // typeChanged(value){
        //     if(value==1){
        //         this.isLed=true;
        //     }else{
        //         this.isLed=false;
        //     }
        // },
        
        async onGroupQuery(){
            const {stationId,saleStatus,playStatus,key,packageIds} = this.searchForm;
            // console.log('sss',this.searchForm)
            var stationIds='';
            if (stationId?.length){
                stationId.forEach(ele => {
                    if (stationIds==""){
                        stationIds=ele[1];
                    }else{
                        stationIds=stationIds+','+ele[1];
                    }
                });
            }
            var tmppackageIds='';

            tmppackageIds=packageIds.join(",");
            await this.$store.dispatch('operate/GetProductList',{stationIds,saleStatus,playStatus,key,packageIds:tmppackageIds}).then(()=>{
                this.productList = this.GetProductList;
            })
        },

        closeAddDialog(){
            this.$refs.newMediaObj.clearSearchList();
        },

        async onAdd(){
            var newList = this.$refs.newMediaObj.form.selectedList
            if (newList.length==0){
                this.$message.success('请选择资源');
                return;
            }
            // console.log('aa',newList)
            newList.forEach(newl => {
                var exist=false;
                this.MediaList.forEach(oldl => {
                    if (oldl.groupId==newl.groupId){
                        exist=true;
                    }
                });
                if (!exist){
                    newl.isNew=1
                    this.MediaList.push(newl);
                    // console.log('ss',this.MediaList)
                }
            });
            this.addDialogVisible=false;
            
        },

        async saveEdit(){
            const {groupId, groupName:name,packageNames,packageId,cost10,saleDateArray,playDateArray,length,times,count,remark} = this.groupForm;//packageIds
            if (!name){
                this.$message.success('请输入产品包名称');
                return;
            }
            if (saleDateArray.length==0){
                this.$message.success('请选择售卖时期');
                return;
            }
            // if (playDateArray.length==0){
            //     this.$message.success('请选择投放时期');
            //     return;
            // }
            if (!cost10){
                this.$message.success('请输入刊例');
                return;
            }
            // if (!cost){
            //     this.$message.success('请输入刊例');
            //     return;
            // }
            if (!packageId){
                this.$message.success('请选择分类');
                return;
            }
            // if (!cost15){
            //     this.$message.success('请输入刊例');
            //     return;
            // }
            if (this.MediaList.length==0){
                this.$message.success('请加入套餐资源');
                return;
            }
            if (this.groupForm.count && this.groupForm.count>=this.MediaList.length ){
                this.$message.success('若限定数量，则数量值应小于资源数量，请检查');
                return;
            }
            var groupIds='';
            this.MediaList.forEach(element => {
                groupIds=groupIds+','+element.groupId;
            });
            groupIds=groupIds.substring(1);

            var img="";
            if (this.groupForm.imgArr){
                this.groupForm.imgArr.forEach(element => {
                    if (img==""){
                        img=element.name;
                    }else{
                        img=img+';'+element.name;
                    }
                });
                
            }
            var cost=cost10
            var cost15=cost10
            const [saleSDate, saleEDate] = saleDateArray;
            var playSDate='2022/7/15', playEDate='2020/12/31'
            // const [playSDate, playEDate] = playDateArray;
            // var saleSDate = saleDateArray[0];
            // var saleEDate = saleDateArray[1];
            // var playSDate = playDateArray[0];
            // var playEDate = playDateArray[1];
            
            console.log('aa',saleDateArray)
            const loading = this.$loading({
                lock: true,
                text: '处理中...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });


            await SaveProductGroup({userId:getToken(), groupId, name,packageNames:packageId,groupIds,cost,cost10,cost15,saleSDate,saleEDate,playSDate,playEDate,length,times,count,remark,img}).then(()=>{
                this.onGroupQuery();
                loading.close();
                this.resultDialogVisible = false
            })
        },

        getImgArr(item){
            if (item.length || item.length==0){
                this.groupForm.imgArr=item;
            }else{
                        // console.log('bb')
            }
            // console.log('item111',item,item.length)
        },

    open(){
        // console.log('do open')
        this.$nextTick(() => {
        //  执行echarts方法
        
        })
      },

      // 每页多少条
            handleSizeChange(val) {
                this.pageSize = val;
            },
            // 当前页
            handleCurrentChange(val) {
                this.currentPage = val;
            },
    }
};
</script>

<style scoped>
.source  >>> .el-divider--horizontal{
    margin:0 0;
}
.beizhu{
    font-size: 8px;
    color:red;
    margin-left:5px;
}

.card-group{
    display:flex;
    flex-wrap:wrap;
    justify-content:center;
}
.group-item{
    padding: 20px;
    margin-left: 10px;
    
}
.text-ele >>>  .el-upload{
    height: 50px;
    width: 100px;
}

.sub-title{
    font-size: large;
    margin-top:4px;
}
.item-pre{
    font-size: larger;
}
.button-footer{
    display: flex;
    flex-direction:row-reverse;
}
.product-name{
    font-size: x-large;
    margin-top:-10px;
}
.card-group{
    display:flex;
    flex-wrap:wrap;
    justify-content:center;
}
.group-item{
    padding: 20px;
    margin-left: 10px;
    
}
.box-card {
    width: 480px;
    
  }
   .text {
    font-size: 14px;
  }

.tag{
    cursor: pointer;margin-left:15px;
    font-size: larger;
}
.tagSel{
    cursor: pointer;margin-left:15px;
    font-size:x-large;
    background-color: #409EFF;
    color: white;
}

  .item {
    /* margin-bottom: 18px; */
    margin-top:-12px;
  }
  .clearfix{
    margin-top: -5px;
}
.subtitle{
    font-weight: bolder;
    padding-right: 5px;
    /* margin-left:20px; */
}
</style>